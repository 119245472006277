import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from '../styles/styles';

import { Helmet } from "react-helmet";

import { observer, inject } from 'mobx-react';

import paymentMethod from '../img/pay_methods.png';

import Button from '@material-ui/core/Button';

import Toast from '../components/Toast'
import { toast } from 'react-toastify'

//import { useAcceptJs } from 'react-acceptjs';
//import HostedForm from '../components/AuthorizeHostedForm';

import { AiOutlineUsergroupAdd } from 'react-icons/ai'
import { IoBusinessOutline } from 'react-icons/io5'
import { TiBusinessCard } from 'react-icons/ti'
import { MdOutlineDateRange, MdOutlineLocationOn } from 'react-icons/md'
import { HiOutlineDocumentText } from 'react-icons/hi'
import { LuCalendarHeart } from 'react-icons/lu'
import { BiSupport } from 'react-icons/bi'
import { RiRefund2Fill } from 'react-icons/ri'
import { TbLocationBolt } from 'react-icons/tb'

//import CreditCardFields from '../components/CreditCardFields'

/*
const authData = {
    apiLoginID: process.env.API_LOGIN_ID,
    clientKey: process.env.API_CLIENT_KEY,
};
*/
const payButtonStyle = {
    color: 'rgb(255, 255, 255)',
    width: '100%',
    padding: '6px 16px',
    border: '0px',
    backgroundColor: '#1E40AF',
    cursor: 'pointer',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    fontWeight: '500',
    borderRadius: '4.5px',
}

//const invoiceId = Array.from({ length: 6 }, () => Math.floor(Math.random() * 10));
//const invoiceDate = new Date().toLocaleDateString('en-US');

/*
const AuthorizeNetHostedForm = ({
    uiStateStore,
    paramsState,
    priceState,
    loadingState,
    setLoadingState,
    showErrorModal,
    showSuccessModal,
    setPaidState,
}) => {
    const handleSubmit = (hostedFormResponse) => {
        if (hostedFormResponse.messages.resultCode === 'Ok') {
            setLoadingState(true)
            showSuccessModal('Verifying payment please wait')
            let body = { ...hostedFormResponse.opaqueData, type: '1' }
            if (uiStateStore.clientDataState.state === '') {
                body = {
                    ...body,
                    ...uiStateStore.clientDataState,
                    ...paramsState,
                }
            } else {
                body = {
                    ...body,
                    ...paramsState,
                    ...uiStateStore.clientDataState,
                }
            }
            fetch(`${process.env.API_URL}/authorize-net-wh/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then(res => res.json()).then(res => {
                if (res.detail === 'Success') {
                    setPaidState(true)
                    setLoadingState(false)
                    showSuccessModal(`Payment Successful. Due Balance $0`)
                } else {
                    showErrorModal(res.detail)
                    setLoadingState(false)
                }
            }).catch(err => {
                showErrorModal('Payment Unsuccessful')
                setLoadingState(false)
            });
        } else {
            showErrorModal('Payment Unsuccessful')
            setLoadingState(false)
        }
    };

    return (
        <div className="row">
            <div className="col-1" />
            <div className="col-5 d-flex align-items-center justify-content-center">
                <HostedForm
                    environment={authData.apiLoginID === '3Vrv7kybU2F' ? 'SANDBOX' : 'PRODUCTION'}
                    authData={authData}
                    onSubmit={handleSubmit}
                    buttonStyle={payButtonStyle}
                    buttonText={loadingState ? 'Verifying Payment..' : `Pay $${uiStateStore.clientDataState.totalPrice}`}
                    formButtonText="Pay"
                    formHeaderText="Pay"
                    billingAddressOptions={{ show: true, required: true }}
                    paymentOptions={{ showCreditCard: true, showBankAccount: false }}
                    disabled={loadingState}
                />
            </div>
        </div>
    );
}
*/

const PaymentPage = ({
    uiStateStore
}) => {
    //const { dispatchData, loading, error } = useAcceptJs({ authData, environment: authData.apiLoginID === '3Vrv7kybU2F' ? 'SANDBOX' : 'PRODUCTION' });

    const [blockRender, setBlockRender] = useState(true);
    const [loadingState, setLoadingState] = useState(false);
    const [priceState, setPriceState] = useState({
        price: 0,
        discount: 0,
        percentage: 0,
        business: '',
        state: '',
        address: '',
        payment_status: false,
    });
    const [paramsState, setParamsState] = useState({
        product: '',
        company: '',
        address: '',
        state: '',
        id: '',
        acc: '',
        product_link_full: '',
        product_link: '',
        product_page: '',
        campaign: '',
        country: 'US',
    });
    const [paidState, setPaidState] = useState(false);
    const [tempCard, setTempCard] = useState({
        number: '',
        expiry: '',
        cvc: '',
    })

    const cardExpiryRef = useRef(null)
    const cardCVCRef = useRef(null)

    const classes = useStyles();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.href.replace('?', '&'));
        let product_link_arr = null;
        try {
            product_link_arr = urlParams.get('product_link').split('-');
        } catch (err) {
            window.location.href = '/form'
        }
        if (
            urlParams.get('company') === null ||
            urlParams.get('product_link') === null ||
            urlParams.get('address') === null
        ) {
            window.location.href = '/form'
        }
        let product_page = null;
        let product_link = null;
        if (product_link_arr !== null) {
            product_page = product_link_arr[0];
            product_link = product_link_arr[1];
        }
        if (urlParams.get('company') === 'MOUSSA INVESTMENTS LLC' || urlParams.get('company') === 'BAMBOO BAKERY LLC') {
            window.location.href = 'https://filingcompliance.gumroad.com/l/nmzdw'
        }
        /*
        allowed_path = [
            'reports',
            'labor',
            'certificate',
            'property',
            'trucking',
            'ucc',
            'ein',
            'bos'
        ]
        */
        let redirectToNewWebsite = false
        let product_id = -1
        let website = ""
        let websiteUrl = ""
        if (
            urlParams.get('product') === 'labor'
        ) {
            websiteUrl = "https://laborcpa.com"
            website = "labor_law"
            product_id = 12
            redirectToNewWebsite = true
        }
        if (
            urlParams.get('product') === 'property'
        ) {
            websiteUrl = "https://propertyassistanceservices.com"
            website = "property"
            product_id = 2
            redirectToNewWebsite = true
        }
        if (
            urlParams.get('product') === 'certificate'
        ) {
            websiteUrl = "https://entitycertificate.com"
            website = "certificate"
            product_id = 1
            redirectToNewWebsite = true
        }

        if (redirectToNewWebsite) {
            fetch("https://unboxking-all-in-one.pages.dev/api/create-session", {
                "headers": {
                    "Content-Type": "application/json",
                },
                "body": JSON.stringify({
                    company: urlParams.get('company'),
                    address: urlParams.get('address'),
                    "product_id": product_id,
                    "website": website,
                    "seller": "state_filing",
                }),
                "method": "POST"
            }).then(res => res.json()).then(json => {
                if (json.status === 200) {
                    window.location.href = `${websiteUrl}/order/${json.result}`
                }
            }).catch(err => {
                console.log(err)
                window.location.reload()
            })
        } else {
            const params = {
                product: urlParams.get('product'),
                company: urlParams.get('company'),
                address: urlParams.get('address'),
                state: urlParams.get('state'),
                id: urlParams.get('id'),
                acc: urlParams.get('acc'),
                product_link_full: urlParams.get('product_link'),
                product_link,
                product_page,
                campaign: urlParams.get('campaign'),
                country: 'US',
            };
            setParamsState(params);

            fetch(`${process.env.API_URL}/info/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...params,
                    json_product: params.product_link,
                })
            }).then(res => {
                if (res.status === 200) return res.json();
            }).then(res => {
                if (res.detail.new_product_link) {
                    setParamsState({
                        ...params,
                        product_link: res.detail.new_product_link,
                    });
                }
                setClientDataState(
                    'payment_box',
                    res.detail.payment_box,
                )
                uiStateStore.changeNavMenuTitle(res.detail.business);
                setPriceState(res.detail);
                setClientDataState(
                    'totalPrice',
                    (res.detail.price + 5.95 - res.detail.discount)
                )
                if (res.detail.payment_status === false) {
                    showErrorModal('Online payment is currently disabled, please send money order or check via mail. Thank you for your patience.')
                }
                setBlockRender(res.detail.block_render);
            }).catch(err => {
                console.log(err);
            });
        }
    }, [])

    /*
    const handleSubmitAuthorizeNet = async () => {
        if (
            uiStateStore.clientDataState.email === '' ||
            uiStateStore.clientDataState.name === '' ||
            uiStateStore.clientDataState.zip === ''
        ) {
            billingNotFilled()
        } else if (uiStateStore.clientDataState.name.includes(' ') === false) {
            showErrorModal('Please fill out full legal name')
        } else if (uiStateStore.clientDataState.email.includes('@') === false) {
            showErrorModal('Please fill correct email address')
        } else if (
            tempCard.number === '' ||
            tempCard.expiry === '' ||
            tempCard.cvc === ''
        ) {
            showErrorModal('Please fill out payment method')
        } else {
            try {
                setLoadingState(true);
                const cardData = {
                    cardNumber: tempCard.number.replaceAll(' ', ''),
                    month: tempCard.expiry.split('/')[0],
                    year: tempCard.expiry.split('/')[1],
                    cardCode: tempCard.cvc.replaceAll(' ', ''),
                }
                const response = await dispatchData({ cardData });
                fetch(`${process.env.API_URL}/authorize-net-wh/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...response.opaqueData,
                        ...uiStateStore.clientDataState,
                        ...paramsState,
                        type: '1',
                    })
                }).then(res => res.json()).then(res => {
                    if (res.detail === 'Success') {
                        setPaidState(true)
                        setLoadingState(false)
                        showSuccessModal(`Payment Successful. Due Balance $0`)
                    } else {
                        showErrorModal(res.detail)
                        setLoadingState(false)
                    }
                }).catch(err => {
                    showErrorModal('Payment Unsuccessful')
                    setLoadingState(false)
                });
            } catch (err) {
                console.log(err)
                showErrorModal('Please double check card information or use another card')
                setLoadingState(false)
            }
        }
    };
    */

    const setCardNumber = (e) => {
        setTempCard({
            ...tempCard,
            number: e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim(),
        })
        if (e.target.value.length === 19) cardExpiryRef.current.focus()
    }

    const setCardExpiry = (e) => {
        let value = e.target.value
        if (value.length === 2 && tempCard.expiry.includes('/') === false) {
            value = value + '/'
            setTempCard({
                ...tempCard,
                expiry: value,
            })
        } else {
            setTempCard({
                ...tempCard,
                expiry: value,
            })
        }
        if (value.length === 5) cardCVCRef.current.focus()
    }

    const setCardCVC = (e) => {
        setTempCard({
            ...tempCard,
            cvc: e.target.value,
        })
    }

    const showSuccessModal = (msg) => {
        toast(
            <Toast title={"Success"} msg={msg} />
        )
    }

    const showErrorModal = (msg) => {
        toast(
            <Toast title={"Error occurred"} msg={msg} />
        )
    }

    const stateAbbreviations = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS",
        "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY",
        "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV",
        "WI", "WY"
    ];

    const setClientDataState = (k, v) => {
        uiStateStore.setClientDataKeyValue(k, v)
    }

    const addExtraService = (service) => {
        setClientDataState(
            service,
            !uiStateStore.clientDataState[service]
        )
        const totalPrice = (
            priceState.price + 5.95 - priceState.discount
        ) + (
                uiStateStore.clientDataState.ein ? 99.95 : 0
            ) + (
                uiStateStore.clientDataState.certificateStanding ? 39.95 : 0
            )
        setClientDataState(
            'totalPrice',
            totalPrice,
        )
    }

    const billingNotFilled = () => {
        showErrorModal('Please fill billing info');
        scrollToBilling();
    }

    const scrollToBilling = () => {
        document.getElementById('af-payment-billing-contact').scrollIntoView({ behavior: 'smooth' })
    }

    const hideExtraBillingButton = () => {
        if (
            uiStateStore.clientDataState.email === ''
        ) {
            return 'block'
        } else {
            return 'hidden'
        }
    }

    const hidePaymentButton = () => {
        if (priceState.payment_status && paidState === false) {
            if (
                paramsState.product === 'labor' ||
                paramsState.product === 'certificate' ||
                paramsState.product === 'ein'
            ) {
                if (
                    uiStateStore.clientDataState.email !== ''
                ) {
                    return 'block'
                }
            }
        }

        return 'hidden'
    }

    const AuthorizeNetPaymentBox = () => {
        if (priceState.payment_status && paidState === false) {
            if (
                paramsState.product === 'labor' ||
                paramsState.product === 'certificate' ||
                paramsState.product === 'ein'
            ) {
                return (
                    <button
                        onClick={billingNotFilled}
                        style={payButtonStyle}>
                        Pay ${uiStateStore.clientDataState.totalPrice}
                    </button>
                )
            }
        }

        return <div />
    }

    const GumroadPaymentBox = () => {
        if (priceState.payment_status && paidState === false) {
            if (
                paramsState.product
            ) {
                return (
                    <div className="pt-9">
                        <a
                            id="gumroad-link"
                            rel="noreferrer"
                            href={`https://${paramsState.product_page}.gumroad.com/l/${paramsState.product_link}?email=${uiStateStore.clientDataState.email}&Address=${paramsState.address}&State=${paramsState.state}&Country=${paramsState.country}&Name=${uiStateStore.clientDataState.name}&Phone=${uiStateStore.clientDataState.phone}&City=${uiStateStore.clientDataState.city}&Zip=${uiStateStore.clientDataState.zip}&Product=${paramsState.product}&Company=${paramsState.company}&Id=${paramsState.id}&Acc=${paramsState.acc}&JsonProduct=${paramsState.product_link_full}`}
                            data-gumroad-single-product="true"
                            target="_self">
                            <Button variant="contained" color="primary" style={{ backgroundColor: '#1E40AF', color: 'white' }} fullWidth>
                                {loadingState ? 'Verifying Payment' : `Pay $${uiStateStore.clientDataState.totalPrice}`}

                                {loadingState && <CircularProgress size={13} className={classes.buttonProgress} />}
                            </Button>
                        </a>
                    </div>
                )
            }
        }

        return <div />
    }

    return blockRender ?
        <div style={{ height: '78vh' }}>
            <LinearProgress />
        </div>
        :
        (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{priceState.business}</title>
                </Helmet>

                {/* Invoice */}
                <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
                    <div className="sm:w-11/12 lg:w-5/6 mx-auto">
                        {/* Card */}
                        <div className="rounded-md flex flex-col p-4 sm:p-10 bg-white shadow-2xl dark:bg-gray-800">
                            {/* Grid */}
                            <div className="flex justify-between">
                                <div>
                                    <h1 className="mt-2 text-lg md:text-xl font-semibold text-blue-800 dark:text-white">
                                        {priceState.business}
                                    </h1>
                                </div>
                                {/* Col */}
                                <div className="text-right">
                                    <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 dark:text-gray-200">

                                    </h2>
                                    <address className="mt-4 not-italic text-gray-800 dark:text-gray-200">
                                        {priceState.address}
                                        <br />
                                        {priceState.state}
                                        <br />
                                        {
                                            priceState.address !== '' &&
                                            priceState.state !== '' &&
                                            'United States'
                                        }
                                        <br />
                                    </address>
                                </div>
                                {/* Col */}
                            </div>
                            {/* End Grid */}

                            <div>
                                {/* Card Section */}
                                <div className="max-w-[85rem] pt-10 lg:pt-14 pb-10 mx-auto">
                                    <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                                        Bill to
                                    </h3>
                                    {/* Grid */}
                                    <div className="grid md:grid-cols-4 border border-gray-200 shadow-sm overflow-hidden dark:border-gray-700">
                                        {/* Card */}
                                        <div
                                            className="block p-4 md:p-5 relative bg-white hover:bg-gray-50 before:absolute before:top-0 before:left-0 before:w-full before:h-px md:before:w-px md:before:h-full before:bg-gray-200 before:first:bg-transparent dark:bg-slate-900 dark:hover:bg-slate-800 dark:before:bg-gray-700"
                                        >
                                            <div className="flex items-center md:grid gap-y-3 gap-x-5">
                                                <IoBusinessOutline className="text-gray-600 stroke-gray-600" />
                                                <div className="grow">
                                                    <p className="text-xs uppercase tracking-wide font-medium text-gray-800 dark:text-gray-200">
                                                        Business
                                                    </p>
                                                    <h3 className="mt-1 text-base font-semibold text-blue-800 dark:text-blue-700">
                                                        {paramsState.company}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Card */}
                                        {/* Card */}
                                        <div
                                            className="block p-4 md:p-5 relative bg-white hover:bg-gray-50 before:absolute before:top-0 before:left-0 before:w-full before:h-px md:before:w-px md:before:h-full before:bg-gray-200 before:first:bg-transparent dark:bg-slate-900 dark:hover:bg-slate-800 dark:before:bg-gray-700"
                                        >
                                            <div className="flex items-center md:grid gap-y-3 gap-x-5">
                                                <TbLocationBolt className="text-gray-600 stroke-gray-600" />
                                                <div className="grow">
                                                    <p className="text-xs uppercase tracking-wide font-medium text-gray-800 dark:text-gray-200">
                                                        State
                                                    </p>
                                                    <h3 className="mt-1 text-base font-semibold text-blue-800 dark:text-blue-700">
                                                        {paramsState.state}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Card */}
                                        {/* Card */}
                                        <div
                                            className="block p-4 md:p-5 relative bg-white hover:bg-gray-50 before:absolute before:top-0 before:left-0 before:w-full before:h-px md:before:w-px md:before:h-full before:bg-gray-200 before:first:bg-transparent dark:bg-slate-900 dark:hover:bg-slate-800 dark:before:bg-gray-700"
                                        >
                                            <div className="flex items-center md:grid gap-y-3 gap-x-5">
                                                <MdOutlineLocationOn className="text-gray-600 stroke-gray-600" />
                                                <div className="grow">
                                                    <p className="text-xs uppercase tracking-wide font-medium text-gray-800 dark:text-gray-200">
                                                        Address
                                                    </p>
                                                    <h3 className="mt-1 text-base font-semibold text-blue-800 dark:text-blue-700">
                                                        {paramsState.address}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Card */}
                                        {/* Card */}
                                        <div
                                            className="block p-4 md:p-5 relative bg-white hover:bg-gray-50 before:absolute before:top-0 before:left-0 before:w-full before:h-px md:before:w-px md:before:h-full before:bg-gray-200 before:first:bg-transparent dark:bg-slate-900 dark:hover:bg-slate-800 dark:before:bg-gray-700"
                                        >
                                            <div className="flex items-center md:grid gap-y-3 gap-x-5">
                                                <HiOutlineDocumentText className="text-gray-600 stroke-gray-600" />
                                                <div className="grow">
                                                    <p className="text-xs uppercase tracking-wide font-medium text-gray-800 dark:text-gray-200">
                                                        Document ID
                                                    </p>
                                                    <h3 className="mt-1 text-base font-semibold text-blue-800 dark:text-blue-700">
                                                        {paramsState.id}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Card */}
                                    </div>
                                    {/* End Grid */}
                                </div>
                                {/* End Card Section */}
                            </div>

                            {/* Table */}
                            <div>
                                <div className="border border-gray-200 p-4 space-y-4 dark:border-gray-700">
                                    <div className="hidden sm:grid sm:grid-cols-3">
                                        <div className="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">
                                            Item
                                        </div>
                                        <div className="text-right text-xs font-medium text-gray-500 uppercase">
                                            Amount
                                        </div>
                                    </div>
                                    <div className="hidden sm:block border-b border-gray-200 dark:border-gray-700" />
                                    <div className="grid grid-cols-3 sm:grid-cols-3 gap-2">
                                        <div className="col-span-full sm:col-span-2">
                                            <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase">
                                                Item
                                            </h5>
                                            <p className="font-medium text-gray-800 dark:text-gray-200">
                                                {
                                                    paramsState.product === 'labor' ?
                                                        'Labor State Law Poster'
                                                        :
                                                        paramsState.product === 'reports' ?
                                                            'File Reports Document Fee'
                                                            :
                                                            paramsState.product === 'paperclip' ?
                                                                'Paperclip'
                                                                :
                                                                paramsState.product === 'ein' ?
                                                                    'EIN Service'
                                                                    :
                                                                    'Document Fee'
                                                }
                                            </p>
                                        </div>
                                        <div>
                                            <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase">
                                                Amount
                                            </h5>
                                            <p className="sm:text-right text-gray-800 dark:text-gray-200">
                                                ${priceState.price}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 sm:grid-cols-3 gap-2">
                                        <div className="col-span-full sm:col-span-2">
                                            <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase">
                                                Item
                                            </h5>
                                            <p className="font-medium text-gray-800 dark:text-gray-200">
                                                Credit Card Processing Fee
                                            </p>
                                        </div>
                                        <div>
                                            <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase">
                                                Amount
                                            </h5>
                                            <p className="sm:text-right text-gray-800 dark:text-gray-200">
                                                $5.95
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Table */}

                            {/* Flex */}
                            <div className="mt-8 flex sm:justify-end px-3">
                                <div className="w-full max-w-2xl sm:text-right space-y-2">
                                    {/* Grid */}
                                    <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                                        <dl className="grid sm:grid-cols-5 gap-x-3">
                                            <dt className="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                                                Total:
                                            </dt>
                                            <dd className="col-span-2 text-gray-500">
                                                ${uiStateStore.clientDataState.totalPrice}
                                            </dd>
                                        </dl>
                                        <dl className="grid sm:grid-cols-5 gap-x-3">
                                            <dt className="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                                                Amount paid:
                                            </dt>
                                            <dd className="col-span-2 text-gray-500">
                                                $
                                                {
                                                    paidState ?
                                                        uiStateStore.clientDataState.totalPrice : 0
                                                }
                                            </dd>
                                        </dl>
                                        <dl className="grid sm:grid-cols-5 gap-x-3">
                                            <dt className="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                                                Due balance:
                                            </dt>
                                            <dd className="col-span-2 text-gray-500">
                                                $
                                                {
                                                    paidState === false ?
                                                        uiStateStore.clientDataState.totalPrice
                                                        :
                                                        0
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                    {/* End Grid */}
                                </div>
                            </div>

                            {
                                uiStateStore.clientDataState.payment_box === 'default-labor' &&
                                <div className="pt-8">
                                    {/* Section */}
                                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-gray-700">
                                        <label
                                            id="af-payment-billing-contact"
                                            className="inline-block text-sm font-medium dark:text-white"
                                        >
                                            Billing contact
                                        </label>
                                        <div className="mt-2 space-y-3">
                                            <input
                                                type="text"
                                                className="rounded-md py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm font-normal focus:border-blue-700 focus:ring-blue-700 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                                placeholder="Email"
                                                value={uiStateStore.clientDataState.email}
                                                onChange={(e) => setClientDataState('email', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* End Section */}
                                </div>
                            }

                            {
                                uiStateStore.clientDataState.payment_box === 'default' &&
                                <div className="pt-8">
                                    {/* Section */}
                                    <div className="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-gray-700">
                                        <label
                                            id="af-payment-billing-contact"
                                            className="inline-block text-sm font-medium dark:text-white"
                                        >
                                            Billing contact
                                        </label>
                                        <div className="mt-2 space-y-3">
                                            <input
                                                type="text"
                                                className="rounded-md py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm font-normal focus:border-blue-700 focus:ring-blue-700 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                                placeholder="Full Name"
                                                value={uiStateStore.clientDataState.name}
                                                onChange={(e) => setClientDataState('name', e.target.value)}
                                            />
                                            <input
                                                type="text"
                                                className="rounded-md py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm font-normal focus:border-blue-700 focus:ring-blue-700 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                                placeholder="Email"
                                                value={uiStateStore.clientDataState.email}
                                                onChange={(e) => setClientDataState('email', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    {/* End Section */}
                                </div>
                            }

                            <div className='pt-3'>
                                <span>By clicking 'Pay,' I agree to and understand that this is a non-government agency and agree to comply with the Terms of Service.</span>
                            </div>

                            <GumroadPaymentBox />

                            {
                                paidState &&
                                <div className="pt-9">
                                    <p className="text-green-700">Thank you for the payment! If you have any questions please contact us at support@laborcomplianceservice.com</p>
                                </div>
                            }

                            <img src={paymentMethod} alt="F.S.C. Payment Methods" width="51%" style={{ display: 'flex', margin: '0 auto' }} />

                            <span className="mx-auto text-sm text-gray-400">Non Government Agency</span>

                            <div>
                                {/* Card Section */}
                                <div className="max-w-[85rem] py-10 lg:py-14 mx-auto">
                                    {/* Grid */}
                                    <div className="grid sm:grid-cols-2 gap-4 sm:gap-6">
                                        {/* Card */}
                                        <div className="flex flex-col bg-white border shadow-sm dark:bg-slate-900 dark:border-gray-800">
                                            <div className="p-4 md:p-5 flex gap-x-4">
                                                <div className="flex-shrink-0 flex justify-center items-center w-[46px] h-[46px] bg-gray-100 dark:bg-gray-800">
                                                    <BiSupport className="text-gray-600 stroke-gray-600" />
                                                </div>
                                                <div className="grow">
                                                    <div className="flex items-center gap-x-2">
                                                        <p className="text-xs uppercase tracking-wide text-gray-500">
                                                            Customer Support
                                                        </p>
                                                    </div>
                                                    <div className="mt-1 flex items-center gap-x-2">
                                                        <h3 className="text-xl sm:text-2xl font-medium text-gray-800 dark:text-gray-200">
                                                            24/7
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Card */}
                                        {/* Card */}
                                        <div className="flex flex-col bg-white border shadow-sm dark:bg-slate-900 dark:border-gray-800">
                                            <div className="p-4 md:p-5 flex gap-x-4">
                                                <div className="flex-shrink-0 flex justify-center items-center w-[46px] h-[46px] bg-gray-100 dark:bg-gray-800">
                                                    <RiRefund2Fill className="text-gray-600 stroke-gray-600" />
                                                </div>
                                                <div className="grow">
                                                    <div className="flex items-center gap-x-2">
                                                        <p className="text-xs uppercase tracking-wide text-gray-500">
                                                            Full Refund
                                                        </p>
                                                    </div>
                                                    <div className="mt-1 flex items-center gap-x-2">
                                                        <h3 className="text-xl font-medium text-gray-800 dark:text-gray-200">
                                                            30 Days
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Card */}
                                    </div>
                                    {/* End Grid */}
                                </div>
                                {/* End Card Section */}
                            </div>

                            <p className="mt-5 text-sm text-gray-500">© 2023 {priceState.business}</p>
                        </div>
                        {/* End Card */}
                    </div>
                </div>
                {/* End Invoice */}
            </div>
        );
}

export default inject('uiStateStore')(observer(PaymentPage));